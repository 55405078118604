<template>
  <pvp-modal width="580" :value="value" @input="toggleModal">
    <div class="title">
      {{
        $t(isCreate ? 'tournaments.creation' : 'tournaments.cloning')
      }}
    </div>
    <BaseForm :validate="validate" @submit="fetchForm">
      <BaseInput
        v-model="tn.name"
        :required="true"
        :label="$t('tournaments.name')"
        :status.sync="errors.name"
        :disabled="isLoading"
        :placeholder="$t('tournaments.select_name')"
      />

      <template v-if="isCreate">
        <GameSelect
          v-model="tn.idGame"
          list-position="top"
          :label="$t('games.single')"
          :required="true"
          :disabled="isLoading"
          :status="errors.idGame"
          :games-id="gamesId"
          @input="onGameChange"
        />
        <bracket-select
          v-model="tn.idSystem"
          list-position="top"
          :label="$t('tournaments.bracketType')"
          :required="true"
          :disabled="isLoading"
          :status="errors.idSystem"
          :id-game="tn.idGame"
          @input="onBracketChange"
        />

        <div class="tournament-create-modal__message">
          <Notification
            type="info"
            :subtitle="bracketTypeNotificationText"
          />
        </div>

        <BaseSelect
          v-model="tn.numTeamMembers"
          list-position="top"
          :label="$t('tournaments.system')"
          label-key="name"
          value-key="id"
          :readonly="appIsMobile"
          :disabled="isLoading"
          :required="true"
          :options="membersCount"
          :placeholder="$t('tournaments.select_format')"
          :status="errors.numTeamMembers"
          @input="skipError('numTeamMembers')"
        >
          <template #selected-option="{ option }">
            {{ option.name }}
          </template>
          <template #option="{ option }">
            {{ option.name }}
          </template>
        </BaseSelect>
      </template>

      <template v-if="isNotHub">
        <pvp-datepicker
          v-model="tn.startReg"
          position="fixed"
          :disabled="isLoading"
          :has-time="true"
          :placeholder="$t('tournaments.select_dateRegStart')"
          :label="$t('tournaments.time_reg-start')"
        />

        <pvp-datepicker
          v-model="tn.start"
          position="fixed"
          :disabled="isLoading"
          :has-time="true"
          :placeholder="$t('tournaments.select_dateStart')"
          :label="$t('tournaments.time_start')"
        />

        <pvp-datepicker
          v-model="tn.end"
          position="fixed"
          :has-time="true"
          :disabled="isLoading"
          :placeholder="$t('tournaments.select_dateEnd')"
          :label="$t('tournaments.time_end')"
        />
      </template>

      <InfoBox v-if="errors.common.length" variant="error">
        <p v-for="(msg, key) in errors.common" :key="key">
          {{ msg }}
        </p>
      </InfoBox>
      <pvp-btn :is-loading="isLoading" type="submit">
        {{ $t('global.done') }}
      </pvp-btn>
    </BaseForm>
  </pvp-modal>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import BracketSelect from '@components/BaseComponents/Select/BracketSelect.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import Notification from '@components/v2/ui/Notification.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import GameSelect from '@components/BaseComponents/Select/GameSelect.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'TournamentCreateModal',
  components: {
    InfoBox,
    BracketSelect,
    Notification,
    BaseInput,
    GameSelect,
    BaseSelect,
    BaseForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    idClone: {
      type: [String, Number],
      default: null,
    },
    organizationId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    tn: {
      name: '',
      idGame: 1,
      idSystem: undefined,
      numTeamMembers: undefined,
      startReg: null,
      start: null,
      end: null,
    },
    errors: {
      name: '',
      idSystem: '',
      numTeamMembers: '',
      common: [],
    },
  }),
  computed: {
    ...mapState('tournaments', ['settings']),
    ...mapGetters('tournaments', [
      'getTournamentSettings',
      'getTournament',
      'getSystems',
    ]),
    ...mapGetters('tournamentSettings', ['getIsHub']),
    ...mapGetters('application', ['getGame', 'appIsMobile']),

    membersCount() {
      return this.tournamentSettings?.systemSettings?.[
        this.tn?.idSystem
      ]?.teamSize?.map((item) => ({
        id: item,
        name: `${item}x${item}`,
      }));
    },
    tournamentSettings() {
      return this.getTournamentSettings(this.tn.idGame) || {};
    },
    gamesId() {
      return Object.keys(this.settings || []) || [];
    },
    isCreate() {
      return !this.idClone;
    },
    tnClone() {
      return this.getTournament(this.idClone);
    },
    isNotHub() {
      const hubId = 9;
      if (this.isCreate) {
        return this.tn?.idSystem !== hubId;
      }
      return this.tnClone?.idSystem !== hubId;
    },
    bracketTypeNotificationText() {
      return Object.values(this.getSystems).find(
        (system) => system.id === this.tn?.idSystem,
      )?.desc;
    },
  },
  watch: {
    value(isOpen) {
      if (isOpen === false) {
        return;
      }

      if (this.isCreate === false) {
        this.tn.name = `${this.tnClone?.name} clone`;
      }
    },
  },
  created() {
    this.onGameChange();
  },
  methods: {
    skipError(param) {
      this.errors[param] = '';
    },

    toggleModal(isOpen) {
      this.$emit('input', isOpen);
    },

    onGameChange() {
      this.skipError('idGame');
      this.tn.idSystem =
        this.tournamentSettings?.defaultSettings?.systemId;
      this.tn.numTeamMembers =
        this.tournamentSettings?.defaultSettings?.teamSize;
    },

    onBracketChange() {
      this.skipError('idSystem');
      const { teamSize = [] } =
        this.tournamentSettings?.systemSettings[this.tn.idSystem] ||
        {};
      this.tn.numTeamMembers =
        teamSize?.find((num) => num === this.tn.numTeamMembers) ||
        teamSize[0];
    },

    validate() {
      let noErrors = true;

      if (this.tn.name.length === 0) {
        this.errors.name = 'error';
        noErrors = false;
      }

      if (this.tn.idGame === undefined) {
        this.errors.idGame = 'error';
        noErrors = false;
      }

      if (this.tn.idSystem === undefined) {
        this.errors.idSystem = 'error';
        noErrors = false;
      }

      if (this.tn.numTeamMembers === undefined) {
        this.errors.numTeamMembers = 'error';
        noErrors = false;
      }

      return noErrors;
    },

    fetchForm() {
      this.errors = {
        name: '',
        numTeamMembers: '',
        idGame: '',
        idSystem: '',
        common: [],
      };

      this.isLoading = true;

      const { start, end, startReg, ...form } = this.tn;

      form.tsStartReg = startReg || 0;
      form.tsStartRun = start || 0;
      form.tsEndRun = end || 0;

      const handleError = ({ error }) => {
        if (_.isArray(error)) {
          this.errors.common = error;
        } else if (_.isString(error)) {
          this.errors.common = [error];
        } else {
          this.errors.common = [...Object.values(error)];
        }
      };

      const handleSuccess = (data) => {
        if (data?.success) {
          this.$router.push({
            name: 'tournament-edit',
            params: {
              tnId: data.id,
              orgId: this.organizationId,
            },
          });
          this.toggleModal(false);

          // =====================================
          // METRICS Турниры. Создание турнира
          const tmrParams = {
            category: 'pvp',
            action: 'tournament_start',
            label: this.getGame(this.tn.idGame).name,
            url: window.location.href,
          };
          pushEvents('pvp_tournament_start', tmrParams);
          // =====================================
        } else {
          this.errors.common = data?.errors;
        }
      };

      if (this.isCreate) {
        form.idOrganization = this.organizationId;
        return api
          .post('/tournament/create', { form })
          .then(handleSuccess)
          .catch(handleError)
          .finally(() => {
            this.isLoading = false;
          });
      }

      return api
        .post(`/tournament/${this.idClone}/clone`, { form })
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: block;
  width: 158px;
  margin: 60px auto 0;
}

.title {
  text-align: center;
  font-weight: bold;
  padding-top: 30px;

  &:not(:empty) {
    @include min-tablet() {
      font-size: 24px;
      padding-left: 30px;
      padding-right: 30px;
    }
    @include max-tablet() {
      font-size: 20px;
      padding-left: 12px;
      padding-right: 12px;
    }

    & + .pvp-form {
      margin-top: 20px;
    }
  }
}

.box-error {
  margin-top: 2em;
}

.loader {
  margin-top: 2em;
}

.tournament-create-modal__message {
  margin: 0.5em 0 1em;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius--medium);
}
</style>
